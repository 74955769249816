html {
  scroll-behavior: smooth;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #013196 #f3f4f6;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f3f4f6;
}

*::-webkit-scrollbar-thumb {
  background-color: #013196;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #013169;
}

a:hover {
  color: #5885c3;
}

.hover:hover {
  color: #5885c3;
}

.animatedBg {
  background-image: url('../public/surface.jpg');
  background-size: cover;
  background-position: center;
  color: #013169;
  animation: animatedBackground 4s ease-in-out infinite alternate;
}

@media screen and (min-width: 768px) {
  .animatedBg {
    background-position-y: 30%;
  }
}

/* STYLE DO REACT MODAL */
.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  background: white;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
}

.modal-close-btn {
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
